<template>
  <q-modal @close="$emit('close')">
    <form class="content" @submit.prevent="">

      <h5>Редактирование акта №{{data.number}}</h5>

      <div class="form-content">
        <div class="content__left">
          <div class="form-item">
            <label>Гос. № ТС</label>
            <q-input-number
              placeholder="М 000 М 000"
              type="text"
              :disabled="false"
              :white="true"
              v-model="liscensePlate"
              license
              :masked="maskVal"
            />
          </div>
          <div class="form-item">
            <label>Перевозчик</label>
            <q-select-search
              :items="organisationSelectItems"
              :selectedItem="organisationSelectItemActive"
              @changeSelect="organisationSelectItemActive = $event"
              :maxHeight="145"
            />
          </div>

          <div class="form-item">
            <label>Клиент</label>
            <q-select
              :items="clientsItems"
              @changeSelect="clientItemActive = $event"
              :selectedItem="clientItemActive"
              :maxHeight="145"
            />
          </div>
        </div>

        <div class="content__right">
          <div class="form-item">
            <label>Категория отходов</label>
            <q-select
              :items="wasteCategorySelectItems"
              @changeSelect="changeWasteCategoryAndType"
              :selectedItem="wasteCategorySelectItemActive"
              :maxHeight="205"
            />
          </div>

          <div class="form-item">
            <label>Вид отходов</label>
            <q-select
              :items="wasteTypeSelectItems"
              @changeSelect="wasteTypeSelectItemActive = $event"
              :selectedItem="wasteTypeSelectItemActive"
              :maxHeight="145"
            />
          </div>

          <div class="form-item">
            <label>Комментарий</label>
            <q-input
              placeholder="Введите комментарий"
              type="text"
              :white="true"
              v-model="comment"
            />
          </div>
        </div>
      </div>

      <div class="bottom-row">
        <span>{{data.date_create !== data.date_update ? `Отредактировано: ${moment(data.date_update).format('DD.MM.YYYY')}` : `Создано: ${moment(data.date_create).format('DD.MM.YYYY')}`}}</span>

        <div class="bottom-row__btn">
          <q-button
            label="Сохранить"
            view="secondary"
            @clicked="submitForm"
            :disabled="!validate"
          />
        </div>
      </div>
    </form>

  </q-modal>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted } from 'vue'
import useApi from '@/composables/useApi'
import moment from 'moment'
import store from '@/store'
import QSelectSearch from '@/components/ui/QSelectSearch.vue'


const props = defineProps({
  data: {
    type: Object,
  },
  waste: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['close', 'updateActsList', 'showNotification'])

const clientsItems = ref([])
const clientItemActive = ref({
  id: '',
  label: 'Выберите клиента'
})

const liscensePlate = ref('')
const comment = ref('')
const organisationSelectItems = ref([]);
const organisationSelectItemActive = ref({id: '', label: ''});

const date = computed(()=> moment(props.data.date_update).utc().format('DD.MM.YYYY'))

const getOrganisations = async() => {
  const transportersData = await useApi('get', `company/get_transporters`)
  console.log(transportersData)

  organisationSelectItems.value = transportersData.transporters.map(item => {
    return {
      id: item.id,
      label: `${item.label}${item.description ? '('+item.description+')' : ''}`
    }
  })
};

const wasteTypeSelectItems = ref([]);
const wasteTypeSelectItemActive = ref({});

onMounted(async ()=> {
  const clients = await useApi('get', 'acts/clients')

  getOrganisations()

  clientsItems.value = clients.data.clients

  clientItemActive.value = {
    id: props.data.client.id,
    label: props.data.client.name
  }

  organisationSelectItemActive.value = {id: props.data.transporter.id, label: props.data.transporter.name}
  liscensePlate.value = props.data.state_number
  wasteCategorySelectItemActive.value = props.data.waste_category
  wasteTypeSelectItemActive.value = props.data.waste_type

  const wasteData = await useApi('get', 'settings/waste/category')
  wasteCategorySelectItems.value = wasteData.waste

  const s = wasteData.waste.find(item => item.id === props.data.waste_category.id)
  wasteTypeSelectItems.value = s.waste_type_selector
})

const wasteCategorySelectItems = ref([]);
const wasteCategorySelectItemActive = ref({
  id: '',
  label: 'Не выбран'
});

const submitForm = async() => {

  if(store.state.isDemoMode){
    emit('close')
    store.dispatch('setDemoModal', true)
    return
  }

  store.dispatch('setGlobalPreloader', true)

  const sendForm = await useApi('post', 'acts/update', {
    id: props.data.id,
    state_number: liscensePlate.value,
    comment: comment.value,
    transporter_id: organisationSelectItemActive.value.id,
    waste_category_id: wasteCategorySelectItemActive.value.id,
    waste_type_id: wasteTypeSelectItemActive.value.id,
    client_id: clientItemActive.value.id
  })
  if (sendForm.act){
    store.dispatch('setGlobalPreloader', false)
    emit('updateActsList')
    emit('showNotification', 'Акт успешно отредактирован')
    emit('close')
  }
}

const validate = computed(() => liscensePlate.value.length >= 8 && organisationSelectItemActive.value.id)

const changeWasteCategoryAndType = async(e) => {
  wasteCategorySelectItemActive.value = e
  wasteTypeSelectItems.value = e.waste_type_selector
  wasteTypeSelectItemActive.value = e.waste_type_selector[0]

  await getOrganisations()

  organisationSelectItemActive.value = {id: '', label: ''}
}

const maskVal = computed(() => Number(liscensePlate.value[0]) >= 0 ? 'DDDDNNDDD' : 'NDDDNNDDD')
</script>

<style scoped lang="scss">
.content{
  width: 648px;
  padding: 36px;
  .form-content{
    display: flex;
    justify-content: space-between;
  }

  &__left{
    width: 50%;
    margin-right: 12px;
  }

  &__right{
    width: 50%;
    margin-left: 12px;
  }
  .form-item{
    margin-bottom: 24px;

    &--btn{
      padding-top: 10px;
    }
  }

  .form-btn{
    margin-top: 10px;
  }

  h5{
    margin-bottom: 22px;
  }

  label{
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
    display: block;
    opacity: .5;
  }
  .form-buttons{
    margin-top: 32px;
  }
}

.bottom-row{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 12px;
  &__btn{
    width: 275px;
  }
  span{
    font-size: 16px;
    opacity: .5;
  }
}
</style>
