<template>
  <div class="user" v-click-outside="clickedOutside">
    <!-- <div class="user__notification">
      <div class="user__new-message"></div>
      <img src="@/assets/images/bell-notification.svg" alt="Уведомления">
    </div> -->
    <div class="user__avatar" @click="isOpenMenu = !isOpenMenu">
      <img src="@/assets/images/no-photo.svg" alt="Аватар пользователя">
    </div>
    <button
      class="user__menu-button"
      :class="{'user__menu-button--active' : isOpenMenu}"
      @click="isOpenMenu = !isOpenMenu"
    >
      <svg class="user__menu-button-arrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.15907 0.477248C1.63952 0.0226454 0.849819 0.0752922 0.395217 0.594838C-0.0593859 1.11438 -0.00673902 1.90409 0.512807 2.35869L2.15907 0.477248ZM11.4924 2.35869C12.0119 1.90409 12.0646 1.11438 11.61 0.594838C11.1554 0.0752922 10.3657 0.0226454 9.84614 0.477248L11.4924 2.35869ZM9.84614 0.477248L5.94773 3.88836L7.59399 5.7698L11.4924 2.35869L9.84614 0.477248ZM0.512807 2.35869L4.41122 5.7698L6.05748 3.88836L2.15907 0.477248L0.512807 2.35869ZM5.94773 3.88836C5.97915 3.86087 6.02606 3.86087 6.05748 3.88836L4.41122 5.7698C5.32236 6.56705 6.68285 6.56705 7.59399 5.7698L5.94773 3.88836Z" fill="#C6C6C6"/>
      </svg>

      <transition name="slide">
        <div class="user__menu" v-if="isOpenMenu">
          <div class="row">
            <div class="organisation" v-if="store.state.user.email !== 'ecology@bashkortostan.ru'">
              Организация
              <div class="organisation__title"> {{$store.state.user.company}} </div>
            </div>

            <div class="btns">
              <router-link
                to="/settings"
                class="settings"
                v-if="$store.state.user.role !== 4 && $store.state.user.email !== 'ecology@bashkortostan.ru'"
              >
                <svg class="settings-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="4" stroke="#C6C6C6" stroke-width="2"/>
                  <path d="M10 2.1547C11.2376 1.44017 12.7624 1.44017 14 2.1547L19.5263 5.3453C20.7639 6.05983 21.5263 7.38034 21.5263 8.8094V15.1906C21.5263 16.6197 20.7639 17.9402 19.5263 18.6547L14 21.8453C12.7624 22.5598 11.2376 22.5598 10 21.8453L4.47372 18.6547C3.23612 17.9402 2.47372 16.6197 2.47372 15.1906V8.8094C2.47372 7.38034 3.23612 6.05983 4.47372 5.3453L10 2.1547Z" stroke-width="2"/>
                </svg>
              </router-link>

              <button
                class="leave"
                @click="logout"
                v-if="!$store.state.isDemoMode"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 8C14 8.55228 14.4477 9 15 9C15.5523 9 16 8.55228 16 8H14ZM16 16.5C16 15.9477 15.5523 15.5 15 15.5C14.4477 15.5 14 15.9477 14 16.5H16ZM6 22H11V20H6V22ZM3 17V7H1V17H3ZM6 4H11V2H6V4ZM14 7V8H16V7H14ZM16 17V16.5H14V17H16ZM11 4C12.6569 4 14 5.34315 14 7H16C16 4.23858 13.7614 2 11 2V4ZM3 7C3 5.34315 4.34315 4 6 4V2C3.23858 2 1 4.23858 1 7H3ZM11 22C13.7614 22 16 19.7614 16 17H14C14 18.6569 12.6569 20 11 20V22ZM6 20C4.34315 20 3 18.6569 3 17H1C1 19.7614 3.23858 22 6 22V20Z" fill="#C6C6C6"/>
                  <path d="M9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13V11ZM21 12L21.8321 12.5547C22.056 12.2188 22.056 11.7812 21.8321 11.4453L21 12ZM19.8321 8.4453C19.5257 7.98577 18.9048 7.8616 18.4453 8.16795C17.9858 8.4743 17.8616 9.09517 18.1679 9.5547L19.8321 8.4453ZM18.1679 14.4453C17.8616 14.9048 17.9858 15.5257 18.4453 15.8321C18.9048 16.1384 19.5257 16.0142 19.8321 15.5547L18.1679 14.4453ZM9 13H21V11H9V13ZM21.8321 11.4453L19.8321 8.4453L18.1679 9.5547L20.1679 12.5547L21.8321 11.4453ZM20.1679 11.4453L18.1679 14.4453L19.8321 15.5547L21.8321 12.5547L20.1679 11.4453Z" fill="#C6C6C6"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </transition>
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router'
import store from '@/store'

const router = useRouter()
const isOpenMenu = ref(false)

const logout = () => {
  store.dispatch('logout')
  router.push('/')
}

const clickedOutside = () => isOpenMenu.value = false
</script>

<style scoped lang="scss">
  .user{
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 30px;

    &:hover{
      .user__menu-button{
        .user__menu-button-arrow{
          path{
            fill: $beckers-knop;
          }
        }
      }
    }


    &__notification{
      margin-top: 9px;
      margin-left: 30px;
      cursor: pointer;
      position: relative;
    }

    &__new-message{
      width: 7px;
      height: 7px;
      background-color: $orange;
      border-radius: 50%;
      position: absolute;
      right: -3px;
      top: -3px;
    }

    &__avatar{
      cursor: pointer;
    }

    &__menu-button{
      width: 32px;
      height: 32px;
      margin-top: -6px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg{
        path{
          transition: ease .2s;
        }
      }
      &-arrow{
        transition: ease .2s;
      }
      &--active{
        .user__menu-button-arrow{
          transform: rotate(180deg);
        }
      }
      &:hover{
        .user__menu-button-arrow{
          path{
            fill: $beckers-knop;
          }
        }
      }
    }

    &__menu{
      background: $white;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      padding: 20px;
      width: 375px;
      position: absolute;
      right: 0px;
      top: 60px;

      .row{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .organisation{
        color: rgba(68, 68, 68, 0.6);
        text-align: left;
        &__title{
          color: $ford-medium-dark;
          font-size: 16px;
          margin-top: 2px;
        }
      }
      .leave{
        display: flex;
        align-items: center;
        &:hover{
          path{
            fill: $beckers-knop;
          }
        }
      }
    }
  }

.btns{
  display: flex;
  align-items: center;

  .settings{
    height: 24px;
    margin-right: 12px;
    .settings-icon{
      path,circle{
        transition: ease .3s;
        stroke: #C6C6C6;
      }
      &:hover{
        path,circle{
          transition: ease .3s;
          stroke: $beckers-knop;
        }
      }
    }
  }
}
</style>
